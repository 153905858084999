<template>
  <v-card>
    <v-container v-if="!isLoading">
      <div>
        <h2>Create a new template</h2>
        <EditorBasic @is-valid="basicValidHandler"></EditorBasic>
        <EditorImpressions
          @is-valid="impressionsValidHandler"
        ></EditorImpressions>

        <v-row>
          <v-col class="text-right">
            <v-btn
              :disabled="!isValid"
              justify-end
              color="primary"
              large
              @click="createTemplate"
            >
              {{ $t('CREATE') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-card v-else class="d-flex justify-center py-5">
      <Loader></Loader>
    </v-card>
  </v-card>
</template>
<script>
import FormMixin from '@/mixins/FormMixin'
import EditorBasic from './editor/Basic'
import EditorImpressions from './editor/Impressions'
import Loader from '@/components/common/Loader'

export default {
  components: {
    EditorBasic,
    EditorImpressions,
    Loader
  },
  mixins: [FormMixin],
  props: {
    inModal: Boolean
  },
  data: () => ({
    basicIsValid: false,
    impressionsIsValid: false
  }),
  computed: {
    isValid: {
      get() {
        this.$debug('isValid', 1)
        return this.basicIsValid === true && this.impressionsIsValid === true
      }
    },
    isLoading() {
      return this.$store.state.templates.editor.isLoading
    }
  },
  watch: {},
  methods: {
    basicValidHandler(value) {
      this.$debug('basicValudHandler', value)
      this.basicIsValid = value
    },
    impressionsValidHandler(value) {
      this.$debug('impressionsValidHandler', value)
      this.impressionsIsValid = value
    },
    async createTemplate() {
      let me = this
      try {
        let template = await me.$store.dispatch('templates/createTemplate')
        me.$toast({
          type: 'success',
          message: `New template "${template.name}" created`
        })
        await me.$redirect({
          name: 'templates',
          params: {
            organisationId: me.$store.state.templates.editor.organisation
          }
        })
      } catch (err) {
        me.$error(err)
      }
    }
  }
}
</script>
