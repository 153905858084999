<template>
  <!-- TODO: This is copy paste from user side. Common component to be used? -->
  <div>
    <v-row>
      <v-col>
        <h2>{{ $t('Campaign impressions') }}</h2>
      </v-col>
    </v-row>
    <v-form v-model="validForm">
      <v-row>
        <v-col xs="12" md="6">
          <v-text-field
            v-model.number="total_impressions"
            :label="$t('Total impressions')"
            filled
            required
            :rules="$rules.number"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col xs="12" md="6">
          <v-text-field
            v-model.number="min_time_between_ads_min"
            :label="$t('Min time between ad views')"
            filled
            required
            :rules="$rules.number"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col xs="12" md="6">
          <v-text-field
            v-model.number="max_daily_imp_per_device"
            :label="$t('Max daily impressions per device')"
            filled
            required
            :rules="$rules.number"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col xs="12" md="6">
          <v-text-field
            v-model.number="max_weekly_imp_per_device"
            :label="$t('Max weekly impressions per device')"
            filled
            :rules="$rules.number"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col xs="12">
          <v-checkbox
            v-model="editable_impressions"
            :label="$t('Campaign impression settings editable by end user')"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import FormMixin from '@/mixins/FormMixin'

export default {
  mixins: [FormMixin],
  data: () => ({
    validForm: false
  }),
  computed: {
    total_impressions: {
      get() {
        return this.$store.state.templates.editor.settings.total_impressions
      },
      set(value) {
        this.$store.commit('templates/set', [
          'editor.settings.total_impressions',
          value
        ])
      }
    },
    min_time_between_ads_min: {
      get() {
        return this.$store.state.templates.editor.settings
          .min_time_between_ads_min
      },
      set(value) {
        this.$store.commit('templates/set', [
          'editor.settings.min_time_between_ads_min',
          value
        ])
      }
    },
    max_daily_imp_per_device: {
      get() {
        return this.$store.state.templates.editor.settings
          .max_daily_imp_per_device
      },
      set(value) {
        this.$store.commit('templates/set', [
          'editor.settings.max_daily_imp_per_device',
          value
        ])
      }
    },
    max_weekly_imp_per_device: {
      get() {
        return this.$store.state.templates.editor.settings
          .max_weekly_imp_per_device
      },
      set(value) {
        this.$store.commit('templates/set', [
          'editor.settings.max_weekly_imp_per_device',
          value
        ])
      }
    },
    editable_impressions: {
      get() {
        return this.$store.state.templates.editor.editable_impressions
      },
      set(value) {
        this.$store.commit('templates/set', [
          'editor.editable_impressions',
          value
        ])
      }
    }
  },
  watch: {
    validForm(value) {
      this.$emit('is-valid', value)
    }
  }
}
</script>
