<template>
  <v-card class="elevation-5">
    <v-card-text class="pl-9 pt-8 pb-1">
      <span class="headline">
        {{ $t('Template list') }}
      </span>
    </v-card-text>
    <v-data-table
      :items="templates"
      hide-default-footer
      class="user-table"
      item-key="name"
      disable-pagination
    >
      <template v-slot:header>
        <thead>
          <tr>
            <th>{{ $t('Name') }}</th>
            <th>
              <v-layout justify-end>{{ $t('Edit') }}</v-layout>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:body="{ items }">
        <!-- TODO: Row function to single element, MUST NOT override action button function -->
        <tbody>
          <tr v-for="template in items" :key="template.id">
            <td>{{ template.name }}</td>
            <td>
              <v-layout justify-end>
                <v-icon right @click="openTemplate(template.id)">
                  mdi-pencil
                </v-icon>
                <v-icon right @click="deleteTemplate(template.id)">
                  mdi-delete
                </v-icon>
              </v-layout>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>
<style lang="sass" scoped>
.user-table
  padding: 24px

  tbody tr:nth-of-type(odd)
    background-color: #EFEFEF

  td
    border: none !important
    &:first-child
      border-top-left-radius: 6px
      border-bottom-left-radius: 6px
    &:last-child
      border-top-right-radius: 6px
      border-bottom-right-radius: 6px
      width: 212px
  tr:hover
    cursor: pointer

  thead
    tr
      th
        font-weight: 300
        font-size: 16px !important
        border: none !important

.duplicate
  margin-left: 10px
</style>
<script>
// TODO: Chechk functionality and styling with other cases when we get those

export default {
  data: () => ({
    templates: []
  }),

  created() {
    let me = this
    me.templates = me.$store.getters['templates/templatesByOrganisation'](
      me.$route.params.organisationId
    )
  },

  methods: {
    async openTemplate(id) {
      let me = this
      await me.$redirect({
        name: 'template-editor',
        params: { templateId: id }
      })
    },
    async deleteTemplate(id) {
      let me = this
      try {
        let template = me.templates.find(c => c.id === id)
        await me.$confirm(
          'Do you really want to delete template "' + template.name + '" ?',
          'Confirm delete'
        )
        await me.$store.dispatch('templates/deleteTemplate', id)
        me.$toast({
          type: 'success',
          message: `Template "` + template.name + `" deleted`
        })
        me.templates = me.$store.getters['templates/templatesByOrganisation'](
          me.$route.params.organisationId
        )
      } catch (err) {
        me.$error(err)
      }
    }
  }
}
</script>
