<template>
  <v-container>
    <v-card class="elevation-4">
      <v-card-text>
        <h3 class="headline mb-4">
          {{ $t('Edit template "%s"', original.name) }}
        </h3>
      </v-card-text>
      <v-card-text>
        <EditorBasic></EditorBasic>
        <EditorImpressions></EditorImpressions>
      </v-card-text>
      <v-card-actions class="justify-end pa-5">
        <v-btn
          color="primary"
          :loading="saving"
          :disabled="!hasChanges"
          @click="saveTemplate"
        >
          {{ $t('Save changes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'
@media #{map-get($display-breakpoints, 'md-and-up')}
  .sticky-float
    position: sticky
    top: 100px
</style>
<script>
import { mapGetters } from 'vuex'
import EditorBasic from './editor/Basic'
import EditorImpressions from './editor/Impressions'

export default {
  components: {
    EditorBasic,
    EditorImpressions
  },

  props: {
    templateId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    init: true,
    saving: false
  }),
  computed: {
    ...mapGetters(['appReady']),
    original() {
      return this.$store.state.templates.campaigns[this.templateId]
    },
    submitting() {
      return this.saving || this.publishing
    },
    hasChanges() {
      return this.$store.getters['templates/editorHasChanges']
    }
  },
  watch: {
    async appReady(status) {
      if (status) {
        await this.initEditor()
      }
    }
  },
  async created() {
    let me = this
    me.$debug('created', me.templateId)
    await me.initEditor()
  },

  methods: {
    async initEditor() {
      let me = this
      if (!me.appReady) {
        return false
      }
      await me.$store.dispatch('templates/loadTemplateToEditor', me.templateId)
      me.init = false
    },
    async saveTemplate() {
      let me = this
      try {
        me.saving = true
        me.$debug('saveTemplate', me.form)
        await me.$store.dispatch('templates/updateTemplateInEditor')
        me.saving = false
        me.$toast({
          type: 'success',
          message: 'Changes saved'
        })

        await me.$redirect({
          name: 'templates',
          params: { organisationId: me.original.organisation }
        })
      } catch (err) {
        if (err.message !== 'cancel') {
          me.$error(err)
        }
      }
    }
  }
}
</script>
