<template>
  <div>
    <v-form v-model="validForm">
      <v-row>
        <v-col lg="6">
          <v-text-field
            v-model="name"
            :label="$t('Name')"
            filled
            required
            :rules="$rules.required"
          ></v-text-field>
        </v-col>

        <v-col lg="6">
          <v-text-field
            v-model="organisationId"
            :label="$t('Organisation ID')"
            filled
            required
            disabled
            :rules="$rules.required"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col lg="6">
          <v-select
            v-model="campaignLengthDays"
            :items="campaignLengths"
            :label="$t('Campaign length')"
            filled
            required
            :rules="$rules.selection"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import FormMixin from '@/mixins/FormMixin'

export default {
  mixins: [FormMixin],
  props: {
    inModal: Boolean
  },
  data: () => ({
    validForm: false,
    campaignLengths: [
      { text: 'One week', value: 7 },
      { text: 'Two weeks', value: 14 },
      { text: 'Three weeks', value: 21 },
      { text: 'Four weeks', value: 28 },
      { text: 'Five weeks', value: 35 },
      { text: 'Seven weeks', value: 42 },
      { text: 'Eight weeks', value: 49 }
    ]
  }),
  computed: {
    id: {
      get() {
        return this.$store.state.templates.editor.id
      },
      set(value) {
        this.$store.commit('templates/set', ['editor.id', value])
      }
    },
    organisationId: {
      get() {
        return this.$store.state.templates.editor.organisation
      },
      set(value) {
        this.$store.commit('templates/set', ['editor.organisation', value])
      }
    },
    name: {
      get() {
        return this.$store.state.templates.editor.name
      },
      set(value) {
        this.$store.commit('templates/set', ['editor.name', value])
      }
    },
    campaignLengthDays: {
      get() {
        return this.$store.state.templates.editor.settings.campaign_length_days
      },
      set(value) {
        this.$store.commit('templates/set', [
          'editor.settings.campaign_length_days',
          value
        ])
      }
    }
  },
  watch: {
    validForm(value) {
      this.$emit('is-valid', value)
    }
  }
}
</script>
